export const b64DecodeUnicode = str =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return `%` + (`00` + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(``)
  )

export const getStaticUrl = url => {
  var static_base = process.env.STATIC_URL_DOMAIN ? `${process.env.STATIC_URL_DOMAIN}` : false
  if(static_base){
    return static_base + url
  }
  return url
}

export const getMediaUrl = url => {
  var static_base = process.env.MEDIA_URL_DOMAIN ? `${process.env.MEDIA_URL_DOMAIN}` : false
  if(static_base){
    return static_base + url
  }
  return url
}

export const getHighlightedIndexes = (space_string) => {
  let indexes = []
  var cur_idx = 0
  for(var i = 0; i < space_string.length; i++){
    if(space_string[i] == `[`){
      indexes.push(cur_idx)
    } else if(space_string[i] == `_`){
      cur_idx += 1
    }
  }

  return indexes
}