/**
 * Answer sheet page
 * 
 * Displays all of the question answers in order, for use in reference for either
 * the emergency message portion of the game, or other puzzles
 * 
 * All of the answers are shown with letter blanks for each letter in the answer, 
 * with some letters highlighted (only if indicated). Only the correct answer gets
 * shown, so even if the team got the answer wrong, they only lost those points,
 * and can still complete the other challenges.
 */

import React, { useContext, useState, useEffect } from 'react'
import ReactCodeInput from 'react-code-input'

import LoggedInWrapper from '../components/loggedinWrapper'
import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'
import { b64DecodeUnicode, getHighlightedIndexes } from '../utils/helpers'
import { getCurrentTeamData } from '../utils/team-data'

const AnswerSheetPage = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState({})
  const [teamData, setTeamData] = useState({})

  useEffect(() => {
    setData(getCurrentHuntData())
    setTeamData(getCurrentTeamData())
    context.setLoading(false)

    // set up interval to pay attention to team answers updates
    const interval = setInterval(() => {
      setTeamData(getCurrentTeamData())
    }, 5000)
    // clear the interval on unmount
    return () => clearInterval(interval)
  }, [])

  const isQuestionFinished = (question_id) => {
    if(teamData.answers === undefined || teamData.answers === null || teamData.answers[question_id] === undefined){
      return false
    }
    const teamAnswer = teamData.answers[question_id]
    if(teamAnswer.isCorrect){
      return true
    } else {
      const allowed_guesses = data.allowedGuesses ? data.allowedGuesses : 2
      if(teamAnswer.numGuesses >= allowed_guesses){
        return true
      }
    }
    return false
  }

  const getAnswer = (question_id) => {
    if(!data) return ``
    let question = false
    // find the question with this id
    data.questions.map((q) => {
      if(q.id == question_id){
        question = q
      }
    })
    // figure out if the team has answered this question, correctly or not
    let show_answer = isQuestionFinished(question_id)

    if(question && show_answer){
      // decode the possible answers
      const answers = b64DecodeUnicode(question.answers_aes_crypt)
      // only return the first one
      return answers.split(`,`)[0]
    }
    return ``
  }

  const getSpaces = (question) => {
    if(question.spaces) {
      return question.spaces
    } else {
      // decode the possible answers
      const answers = b64DecodeUnicode(question.answers_aes_crypt)
      // only return the first one
      const answer = answers.split(`,`)[0].trim()
      var spaces = answer.replace(/[^\s]/g, `_`)
      spaces = spaces.replace(/\s+/g, `+`)
      return spaces
    }
  }

  return (
    <LoggedInWrapper>
      {data.hunt !== undefined && (
        <>
          <div className="site-sub-header px-4 py-2">
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <Link to={`/`} className={`back-arrow`}>
                  <SvgArrow />
                </Link>
              </div>
              <div className="col-10 text-right">
                <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>
                  {data.hunt.answer_sheet_title
                    ? data.hunt.answer_sheet_title
                    : `Team Answer Sheet`}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            <div className={`card mx-4`}>
              {/* <div className="card-header text-center">
                <h3>
                  {data.hunt.bonus_challenge_card_heading
                    ? data.hunt.bonus_challenge_card_heading
                    : `For Extra Points...`}
                </h3>
              </div> */}
              <div className="card-body pb-3">
                <div className="card-text" dangerouslySetInnerHTML={{ __html: data.hunt.answer_sheet_text }} />
                {data.questions !== undefined &&
                <ol className="pl-3">
                  {data.questions.map((question, index) => {
                    const answer_spaces = getSpaces(question)
                    const words = answer_spaces.split(`+`)
                    const answer = getAnswer(question.id)
                    const answer_words = answer.split(` `)
                    return (
                      <li key={question.id} className="mb-2">
                        {words.map((word, idx) => {
                          const num_spaces = word.match(/_/g).length
                          const highlighted_indexes = getHighlightedIndexes(word)
                          return(
                            <span className="answer-input mr-3" key={question.id+`-`+idx}>
                              <ReactCodeInput 
                                label={`Question ${idx + 1}`}
                                type="text"
                                disabled={true}
                                fields={num_spaces}
                                autoFocus={false}
                                forceUppercase={true}
                                highlightCharIndex={highlighted_indexes}
                                initialValue={answer_words[idx] ? answer_words[idx] : ``}
                              />
                            </span>
                          )
                        }
                        )}
                      </li>
                    )
                  }
                  )}
                </ol>}
              </div>
            </div>
          </div>
        </>
      )}
    </LoggedInWrapper>
  )
}

export default AnswerSheetPage